<template>
  <main>
    <header class="layer-primary">
      <div class="main-img">
        <img loading="lazy" alt="" src="@/assets/sommaire-parents.jpg" />
      </div>
    </header>
    
    <section class="section-content" id="section-content">
      <header>
        <div class="sidebar-primary txtcenter" style="background-image: url(https://www.maine-et-loire.fr/typo3conf/ext/ressource_maine-et-loire/img/sidebar-primary-bg-icon.svg); background-position: bottom right; background-repeat: no-repeat; background-size: contain">
          <span class="h2-like">Les autres modes d’accueil</span>
          <div class="ce-textpic ce-center ce-above">
            <div class="ce-bodytext">
              <ul>
                <li>
                  <a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/modes-de-garde/mam" target="_blank" title="Maisons d'assistants maternels - Nouvelle fenêtre">Maisons d'assistants maternels</a>
                </li>
                <li>
                  <a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/modes-de-garde/creches-halte-garderies" target="_blank" title="Crèches en Maine-et-Loire - Nouvelle fenêtre">Crèches en Maine-et-Loire</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <ul id="site-nav-breadcrumbs" class="site-nav-breadcrumbs">
          <li>
            <router-link :to="{name: 'accueil'}">Accueil</router-link>
          </li>
          <li>Comment trouver un ou une assistante maternelle ?</li>
        </ul>
        <h1>Comment trouver un ou une assistante maternelle ?</h1>
        
        <p class="intro" id="introPage">Vous souhaitez confier votre enfant à un ou une assistante maternelle ? Le Département vous informe sur ce mode de garde.</p>
        <div class="toolbox"></div>
        
        <!-- Bloc chapô -->
      </header>
      <div>
        <aside class="sidebar-secondary">
          <nav class="page-nav" id="page-nav" aria-label="Sommaire de la page">
            <span>Sommaire</span>
            <ul>
              <li><router-link to="#assistant-maternel-kesako">Qu’est-ce qu’un ou une assistante maternelle ?</router-link></li>
              <li><router-link to="#etre-employeur">Être employeur d’une assistante maternelle</router-link></li>
              <li><router-link to="#le-metier-et-ses-specificites">Le métier et ses spécificités</router-link></li>
              <li><router-link to="#trouver-un-ou-une-assistante-maternelle">Trouver un ou une assistante maternelle ?</router-link></li>
              <li><router-link to="#vos-interlocuteurs">Vos interlocuteurs</router-link></li>
            </ul>
          </nav>
          <a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/modes-de-garde/relais-assistants-maternels" class="btn-cta-standard-large" target="_blank" title="Une question ? Contactez votre relais petite enfance - Nouvelle fenêtre">
            <span class="fa fa-solid fa-home fa-3x"></span>
            Une question ? Contactez votre relais petite enfance
          </a>
        </aside>
        <article id="main-content">
          <h2 id="assistant-maternel-kesako">Qu’est-ce qu’un ou une assistante maternelle ?</h2>
          
          <p>
            L’assistant ou l’assistante maternelle est un
            <strong>
              professionnel agréé pour accueillir régulièrement à son domicile ou en
              <a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/modes-de-garde/mam" target="_blank" title="Faire garder son enfant en Maison d'assistants maternels - Nouvelle fenêtre">Maison d'assistants maternels</a>
              de un à quatre enfants
            </strong>
            (voire plus sur dérogation). Elle assure dans un climat familial la santé, la sécurité et l’épanouissement de l’enfant qui lui est confié par ses parents.
          </p>
          <p>C'est le parent qui est directement employeur de l'assistant maternel et lui verse son salaire.</p>
          <div class="essential">
            <h2>Le saviez-vous ?</h2>
            <p>C'est le Département qui attribue l'agrément aux assistants maternels, en fonction du respect d'un référentiel précis. Cet agrément fait l'objet d'une réévaluation tous les cinq ans.</p>
          </div>     
          
          <h2 id="etre-employeur">Être employeur d’une assistante maternelle</h2>
          <section class="layer-si layer-without-ending-link">
            <div class="layer-summary-filters">
              <div class="layer-summary-item no-flip">
                <a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/modes-de-garde/assistants-maternels" target="_blank" title="Mon rôle d’employeur - Nouvelle fenêtre">
                  <div class="layer-summary-item-front">
                    <p class="layer-summary-item-title">
                      Mon rôle d’employeur
                    </p>
                  </div>
                </a>
              </div>
              <div class="layer-summary-item no-flip">
                <a href="https://www.maine-et-loire.fr/aides-et-services/professionnels/enfance/reglementation-assistants-maternels/contractualisation" target="_blank" title="Le contrat - Nouvelle fenêtre">
                  <div class="layer-summary-item-front">
                    <p class="layer-summary-item-title">
                      Le contrat
                    </p>
                  </div>
                </a>
              </div>
              <div class="layer-summary-item no-flip">
                <a href="https://www.maine-et-loire.fr/aides-et-services/professionnels/enfance/reglementation-assistants-maternels/documents-a-demander" target="_blank" title="Les documents à transmettre - Nouvelle fenêtre">
                  <div class="layer-summary-item-front">
                    <p class="layer-summary-item-title">
                      Les documents à transmettre
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </section>
          
          <h2 id="le-metier-et-ses-specificites">Le métier et ses spécificités</h2>
          <section class="layer-si layer-without-ending-link">
            <div class="layer-summary-filters">
              <div class="layer-summary-item no-flip">
                <a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/modes-de-garde/mam" target="_blank" title="L’accueil en MAM - Nouvelle fenêtre">
                  <div class="layer-summary-item-front">
                    <p class="layer-summary-item-title">
                      L’accueil en MAM
                    </p>
                  </div>
                </a>
              </div>
              <div class="layer-summary-item no-flip">
                <a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/metiers-de-la-petite-enfance/devenir-assistant-maternel/droits-et-obligations" target="_blank" title="Les droits des assistants maternels - Nouvelle fenêtre">
                  <div class="layer-summary-item-front">
                    <p class="layer-summary-item-title">
                      Les droits des assistants maternels 
                    </p>
                  </div>
                </a>
              </div>
              <div class="layer-summary-item no-flip">
                <a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/metiers-de-la-petite-enfance/devenir-assistant-maternel/agrement" target="_blank" title="Comprendre l’agrément - Nouvelle fenêtre">
                  <div class="layer-summary-item-front">
                    <p class="layer-summary-item-title">
                      Comprendre l’agrément
                    </p>
                  </div>
                </a>
              </div>
              <div class="layer-summary-item no-flip">
                <a href="https://www.maine-et-loire.fr/aides-et-services/professionnels/enfance/reglementation-assistants-maternels/medicaments" target="_blank" title="Les questions médicales - Nouvelle fenêtre">
                  <div class="layer-summary-item-front">
                    <p class="layer-summary-item-title">
                      Les questions médicales
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </section>
          
          <h2 id="trouver-un-ou-une-assistante-maternelle">Trouver un ou une assistante maternelle ?</h2>
          <p>Sur le site assmat.maine-et-loire.fr, vous pouvez trouver la liste des professionnels correspondant à vos critères de recherche. Le contenu de ce site internet est remis à jour chaque soir, en fonction des disponibilités déclarées par les assistantes maternelles elles-mêmes.</p>
          <router-link :to="{name: 'recherche'}" class="btn-cta-standard">Trouver un ou une assistante maternelle</router-link>
          
          <h2 id="vos-interlocuteurs">Vos interlocuteurs</h2>
          <h3>Le Relais petite enfance (RPE)</h3>
          <p>Un lieu d'informations pour les parents et pour les professionnels de la petite enfance.</p>
          <ul>
            <li>Informer sur les différents modes d'accueil</li>
            <li>Informer sur le rôle d’employeur</li>
            <li>Accompagner les assistants maternels dans leur pratique</li>
            <li>Être un lieu de rencontres et d'échanges</li>
          </ul>
          <p>
            C'est auprès du RPE que vous pouvez vous renseigner sur les formalités du contrat de travail ou vos questions sur les déductions fiscales par exemple.<br>
            <a href="https://monenfant.fr/web/guest/les-relais-petite-enfance" target="_blank" title="Les missions des RPE expliquées par la CAF - Nouvelle fenêtre">Les missions des RPE expliquées par la CAF.</a>
          </p>
          
          <h3>Pajemploi.fr</h3>
          <ul>
            <li>Trouver des informations à jour sur la réglementation</li>
            <li>Faire ses déclarations en ligne</li>
            <li>Payer automatiquement votre assmat avec pajemploi +</li>
            <li><a href="https://www.pajemploi.urssaf.fr/pajewebinfo/cms/sites/pajewebinfo/accueil.html" target="_blank" title="Consulter le site Pajemploi.fr - Nouvelle fenêtre">Consulter le site Pajemploi.fr</a></li>
          </ul>
          
          <h3>La CAF</h3>
          <ul>
            <li>Faire une demande d’aide</li>
            <li>Obtenir votre numéro d’immatriculation avant d’embaucher une assistante maternelle</li>
            <li>Signaler des modifications de votre situation personnelle</li>
            <li><a href="https://monenfant.fr/" target="_blank" title="Consulter le site monenfant.fr - Nouvelle fenêtre">Consulter le site monenfant.fr</a></li>
          </ul>
          
          <h3>L’URSSAF</h3>
          <ul>
            <li>Faire votre déclarations employeur si votre enfant a plus de six ans</li>
            <li><a href="https://www.urssaf.fr/portail/home.html" target="_blank" title="Consulter le site de l’Urssaf - Nouvelle fenêtre">Consulter le site de l’Urssaf</a></li>
          </ul>
          
          <h3>Legifrance</h3>
          <ul>
            <li>Consulter la convention collective ou le référentiel des assistants maternels</li>
            <li><a href="https://www.legifrance.gouv.fr/" target="_blank" title="Consulter le site Legifrance - Nouvelle fenêtre">Consulter le site Legifrance</a></li>
          </ul>
          
          <h3>Pôle emploi</h3>
          <ul>
            <li>Remplir l’attestation employeur de fin de contrat de votre assistant maternel</li>
            <li><a href="https://www.pole-emploi.fr/accueil/" target="_blank" title="Consulter le site Pôle emploi - Nouvelle fenêtre">Consulter le site Pôle emploi</a></li>
          </ul>
          
          <!--Suite ecosysteme-->
        </article>
      </div>
      
      <!--Strate actu-->
      <footer>
        <div class="layer-news-list">
          <h2>Actualité de l'enfance en Maine-et-Loire</h2>
          <actualities></actualities>
        </div>
      </footer>
    </section>
  </main>
</template>

<script>
import actualities from '@/components/actualities.vue';

export default {
  name: 'parent',
  components: {
    actualities
  }
};

</script>
